<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col cols="12" md="8" sm="12"
        ><b-card>
          <b-row>
            <b-col cols="12" md="12" sm="12">
              <v-select
                placeholder="Ürün Seç"
                class="select-size-sm"
                v-model="selectedProduct"
                :getOptionLabel="
                  (product) => product.barcode + ' - ' + product.name
                "
                :reduce="(product) => product"
                :options="products"
                @input="addProduct()"
              />
            </b-col>
          </b-row>
        </b-card>

        <!-- xx -->
        <b-row style="overflow: auto; max-height: 50vh">
          <b-col
            cols="12"
            md="12"
            sm="12"
            v-for="sell in sellProduct"
            :key="sell.id"
            ><b-card>
              <b-row>
                <b-col cols="10">
                  <h5>{{ sell.name }}</h5>
                </b-col>
                <b-col cols="2">
                  <b-button
                    @click="deleteProduct(sell)"
                    variant="danger"
                    style="float: right"
                    size="sm"
                    >Sil</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="6">
                  <b-form-spinbutton
                    v-model="sell.count"
                    size="sm"
                    inline
                    @change="handleButton(sell)"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    label="Toplam"
                    v-model="sell.totalPrice"
                    style="float: right"
                    size="sm"
                    class="text-right w-50"
                    type="number"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- xx -->
      </b-col>

      <b-col cols="12" md="4" sm="12" class="text-right">
        <b-card>
          <b-row class="mb-0"
            ><b-col cols="10" md="10" sm="12" class="text-left">
              <v-select
                placeholder="Cari Seç"
                class="select-size-sm"
                v-model="selectedCurrentId"
                :getOptionLabel="(current) => current.fullName"
                :reduce="(client) => client.id"
                :options="clients"
            /></b-col>
            <b-col cols="2" md="2" sm="12">
              <b-button variant="primary" size="sm" @click="addClients()">
                <feather-icon icon="PlusIcon" /> </b-button
            ></b-col>
          </b-row>

          <hr />
          <div class="price-details">
            <h6 class="price-title">Satış Özeti</h6>
            <ul class="list-unstyled">
              <li v-for="row in sellProduct" :key="row.id" class="price-detail">
                {{ row.name }} <b> X </b> {{ row.count }}
              </li>
            </ul>
            <hr />
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">Toplam</div>
                <div class="detail-amt font-weight-bolder">
                  {{ formatCurrency(totalSellPrice) }}
                </div>
              </li>
            </ul>
            <b-button variant="primary" block size="sm" @click="sellSend()">
              Satış Oluştur
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-prevent-client"
      ref="my-modal"
      title="Cari Ekle"
      ok-title="Ekle"
      cancel-title="İptal"
      cancel-variant="outline-secondary"
      @ok="sendClient"
      size="lg"
      size-sm
    >
      <form ref="form" @submit.stop.prevent="add">
        <b-form-group label="Ad,Soyad" label-for="name-input">
          <b-form-input size="sm" id="name-input" v-model="client.fullName" />
        </b-form-group>
        <b-form-group label="Telefon Numarası" label-for="name-input">
          <b-form-input
            size="sm"
            id="name-input"
            v-model="client.phone"
            type="number"
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BButton,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormSpinbutton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTable,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    ToastificationContent,
    BFormCheckbox,
    vSelect,
    BFormSpinbutton,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      stickyHeader: false,
      show: false,
      perPage: 8,
      pageOptions: [8, 10, 12],
      currentPage: 1,
      filter: null,

      clients: [], //Carileri Getircem
      products: [],
      selectedCurrentId: null,
      selectedProduct: null,
      sellProduct: [],
      client: {
        fullName: "",
        phone: "",
      },
    };
  },
  computed: {
    totalSellPrice() {
      var price = 0;

      for (var i = 0; i < this.sellProduct.length; i++) {
        price = Number(price) + Number(this.sellProduct[i].totalPrice);
      }

      return price;
    },
  },

  created() {
    this.getProduct();
    this.getClients();
  },
  methods: {
    async sellSend() {
      if (this.selectedCurrentId == null || this.selectedCurrentId == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Cari Seçimi Boş Olamaz..",
          },
        });
        return;
      }
      if (this.totalSellPrice == null || this.totalSellPrice == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Toplam Fiyat Boş Olamaz...",
          },
        });
        return;
      }
      if (this.sellProduct == null || this.sellProduct.length == 0) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Sepet Boş Olamaz...",
          },
        });
        return;
      }

      var sendModel = {
        rows: this.sellProduct,
        clientId: this.selectedCurrentId,
        totalPrice: this.totalSellPrice,
      };

      this.$http
        .post("Sells", sendModel)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Başarılı`,
                icon: "CoffeeIcon",
                variant: "success",
                text: "Satış Başarı İle Oluşturuldu..",
              },
            });

            this.selectedCurrentId = null;
            this.sellProduct = [];
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async sendClient() {
      if (this.client.fullName == null || this.client.fullName == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Ad Boş Olamaz...",
          },
        });
        return;
      }
      if (this.client.phone == null || this.client.phone == "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: "Telefon Boş Olamaz...",
          },
        });
        return;
      }
      this.$http
        .post("Clients", this.client)
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;
            this.getClients();
            this.$bvModal.hide("modal-prevent-client");
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    deleteProduct(product) {
      const index = this.sellProduct.indexOf(product);
      if (index !== -1) {
        this.sellProduct.splice(index, 1);
      }
    },
    handleButton(sell) {
      for (var i = 0; i < this.sellProduct.length; i++) {
        if (this.sellProduct[i].id == sell.id) {
          this.sellProduct[i].totalPrice =
            Number(this.sellProduct[i].sellPrice) *
            Number(this.sellProduct[i].count);
        }
      }
    },
    async addProduct() {
      var model = {
        id: this.selectedProduct.id,
        name: this.selectedProduct.name,
        count: 1,
        sellPrice: this.selectedProduct.sellPrice,
        totalPrice: this.selectedProduct.sellPrice,
      };
      var rowsFilter = this.sellProduct.filter(
        (s) => s.id == this.selectedProduct.id
      );

      if (rowsFilter.length > 0) {
        rowsFilter[0].count = rowsFilter[0].count + 1;
        this.handleButton(rowsFilter[0]);
      } else {
        this.sellProduct.push(model);
      }
      this.selectedProduct = null;
    },
    async addClients() {
      this.client.fullName = "";
      this.client.phone = "";
      this.$bvModal.show("modal-prevent-client");
    },
    async getClients() {
      this.show = true;
      this.$http
        .get("Clients")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;

            this.clients = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    formatCurrency(amount) {
      const options = {
        style: "currency",
        currency: "TRY",
        currencyDisplay: "symbol", // Use the currency symbol
      };
      const formatter = new Intl.NumberFormat("tr-TR", options);
      return formatter.format(amount);
    },
    async getProduct() {
      this.show = true;
      this.$http
        .get("Products")
        .then(async (response) => {
          if (response.status == 200) {
            this.show = false;

            this.products = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
  },
};
</script>

<style></style>
